import {
  chakra,
  Box as CkBox,
  Text as CkText,
  Button as CkButton,
  ModalHeader as CkModalHeader,
  ModalContent as CkModalContent,
  ModalFooter as CkModalFooter,
  Select as CkSelect
} from '@chakra-ui/react'
import { EZIndexLayer } from 'enums/theme'
import { BaseStyle } from 'types'
import chakraShouldForwardProp from 'utils/chakraShouldForwardProp'

export const Container = chakra(CkBox, {
  baseStyle: () => ({
    background: 'white',
    position: 'sticky',
    zIndex: EZIndexLayer.FILTER_BAR,
    top: '64px'
  })
})

export const RoomFilterWrapper = chakra(CkBox, {
  baseStyle: () => ({
    display: { base: 'none', lg: 'block' },
    boxShadow: 'base'
  })
})

export const RoomFilterContainer = chakra(CkBox, {
  baseStyle: () => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingY: 4,
    paddingX: { base: 0, lg: 8 },
    flexWrap: { base: 'wrap', lg: 'nowrap' },
    gap: { base: 6, lg: 4 }
  })
})

export const Text = chakra(CkText, {
  baseStyle: () => ({
    fontSize: 'lg',
    lineHeight: 7,
    color: 'gray.500',
    fontWeight: 600,
    marginInlineEnd: { base: '0.75rem !important', md: '1.5rem !important' }
  })
})

export const ModalHeader = chakra(CkModalHeader, {
  baseStyle: () => ({
    boxShadow: 'base',
    marginBottom: '1px'
  })
})

export const ButtonModal = chakraShouldForwardProp(CkButton, (props: BaseStyle) => ({
  width: '50%',
  background: props?.resetButton ? 'transparent' : 'teal.500',
  border: props?.resetButton && 'none',
  color: props?.resetButton ? 'teal' : '#fff',
  transition: 'all 0.2s ease-in-out',
  height: { base: '48px', lg: '40px' },
  fontSize: { base: 'lg', lg: 'md' },
  _hover: {
    background: !props?.resetButton && 'teal.600',
    transition: 'all 0.2s ease-in-out'
  },
  _active: {
    background: !props?.resetButton && 'teal.700',
    transition: 'all 0.2s ease-in-out'
  }
}))

export const ModalContent = chakra(CkModalContent, {
  baseStyle: () => ({
    marginTop: 0
  })
})

export const ModalFooter = chakra(CkModalFooter, {
  baseStyle: () => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  })
})

export const SelectWrapper = chakra(CkBox, {
  baseStyle: () => ({
    width: { base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' },
    height: '40px'
  })
})

export const Select = chakraShouldForwardProp(CkSelect, (props: BaseStyle) => ({
  color: '#000',
  borderRadius: '6px',
  border: '1px solid',
  borderColor: props?.active ? 'teal.500' : 'gray.200',
  _hover: { borderColor: props?.active ? 'teal.500' : 'gray.300' },
  _focus: { borderColor: props?.active ? 'teal.500' : 'gray.300' }
}))

export const ButtonApplyFilter = chakra(CkButton, {
  baseStyle: () => ({
    background: 'teal.500',
    color: '#fff',
    transition: 'all 0.2s ease-in-out'
  })
})
