export enum MediaType {
  IMAGE = 'Image',
  VIDEO = 'Video',
  FLOOR_PLAN = 'FloorPlan',
  VR = 'VR'
}

export enum MediaMode {
  PHOTO = 'Photo',
  MAP = 'Map',
  VR = 'VR'
}
