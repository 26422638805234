import React from 'react'
import EFeatureFlags from 'enums/featureFlags'
import { EBathRoomTypeEnum, EPlaceTypeEnum } from 'enums/roomDefaultValue'
import get from 'lodash/get'
import { FlagValue, useFlag } from 'react-unleash-flags'
import { getHouseDefaultValue } from 'API/defaultValue'
import { getHouseListByFilter } from 'API/listing'
import { getMetros } from 'API/metro'
import MainLayout from 'components/Layout/MainLayout'
import ListingPage from 'components/pages/ListingPage'
import { IHouseDefaultValue } from 'interfaces/houseDefaultValue'
import { IListingFilter } from 'interfaces/listing'
import { IMetro } from 'interfaces/metro'
import { IProperty } from 'interfaces/property'
import { getValidArray } from 'utils/common'

interface IListingPageProps {
  houseList: IProperty[]
  countHouseList: number
  defaultHouse: IHouseDefaultValue
  metroList: IMetro[]
  isEntireHouse: boolean
}

const ListPage = (props: IListingPageProps) => {
  const { houseList, countHouseList, isEntireHouse, metroList } = props
  const entireHouseFeatureFlagName: string = `${process.env.REACT_APP_FLAGS_CTX_APP_NAME}-${EFeatureFlags.WEBSITE_LISTING_PAGE_ENTIRE_HOUSE}`
  const entireHouseFeatureFlag: FlagValue | undefined = useFlag(entireHouseFeatureFlagName)
  const isEnableEntireHouseFeature: boolean | undefined = entireHouseFeatureFlag && entireHouseFeatureFlag.enabled

  const validHouseList: IProperty[] = !isEnableEntireHouseFeature
    ? getValidArray(houseList)?.filter((house) => house.placeType !== EPlaceTypeEnum.ENTIRE_HOUSE) ?? houseList
    : houseList
  const countValidHouseList: number = getValidArray(validHouseList)?.length ?? countHouseList

  return (
    <MainLayout title="HomeRoom | Listing" isEntireHouse={isEntireHouse} metroList={metroList}>
      <ListingPage {...props} houseList={validHouseList} countHouseList={countValidHouseList} />
    </MainLayout>
  )
}

export default ListPage

export async function getServerSideProps(context: { query: any }) {
  try {
    const { query } = context
    const { moveDate, metro, price, bathRoomType, placeType = EPlaceTypeEnum.PRIVATE_ROOM } = query
    const filterBathRoomType: EBathRoomTypeEnum = bathRoomType ?? ''
    const filterPlaceType: EPlaceTypeEnum = placeType ?? EPlaceTypeEnum.PRIVATE_ROOM
    const isEntireHouse: boolean = filterPlaceType === EPlaceTypeEnum.ENTIRE_HOUSE
    let filterStartPrice
    let filterEndPrice
    let filterMoveDate

    if (price) {
      const priceRange: string[] = price.split('-')
      filterStartPrice = Number(priceRange[0])
      filterEndPrice = Number(get(priceRange, '[1]', ''))
    }

    if (moveDate) {
      filterMoveDate = new Date(moveDate)
    }

    const houseFilter: IListingFilter = {
      metro,
      bathRoomType: filterBathRoomType,
      startPrice: filterStartPrice,
      endPrice: filterEndPrice,
      moveDate: filterMoveDate,
      placeType: filterPlaceType
      //* INFO: Temporarily comment LeaseLengths to reuse later
      // leaseLengths: filterLeaseLength
    }

    const [houseList, defaultHouse, metroList] = await Promise.all([
      getHouseListByFilter(houseFilter),
      getHouseDefaultValue(),
      getMetros({
        where: { isActive: { neq: false } }
      })
    ])

    return {
      props: {
        houseList,
        countHouseList: getValidArray(houseList)?.length ?? 0,
        defaultHouse,
        metroList,
        isEntireHouse
      }
    }
  } catch (error) {
    console.log('listing-page: getServerSideProps -> error', error)
    return {
      props: { houseList: [], countHouseList: 0, defaultHouse: {}, metroList: [], isEntireHouse: false }
    }
  }
}
