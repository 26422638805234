import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
dayjs.extend(isSameOrBefore)

export function getCarouselTransformValue(transformValue: string): string {
  if (!transformValue?.includes('calc')) {
    return transformValue
  }
  const originalTransformValue: string = transformValue?.substring(
    transformValue.indexOf('-'),
    transformValue.indexOf('%') + 1
  )
  return `translate3d(${originalTransformValue}, 0px, 0px)`
}
