import {
  chakra,
  Box as CkBox,
  SimpleGrid as CkSimpleGrid,
  Flex as CkFlex,
  Button as CkButton,
  Text as CkText
} from '@chakra-ui/react'
import { EZIndexLayer } from 'enums/theme'
import { BaseStyle } from 'types'
import chakraShouldForwardProp from 'utils/chakraShouldForwardProp'

export const Container = chakra(CkBox)

export const ListingPageContainer = chakra(CkSimpleGrid, {
  baseStyle: () => ({
    width: { base: 'stretch', lg: '50vw' },
    gridTemplateColumns: { base: 'repeat(1, minmax(0, 1fr))', md: 'repeat(2, minmax(0, 1fr))' },
    gridGap: { base: 4, md: 8 },
    alignSelf: 'flex-start',
    justifyContent: 'center',
    background: '#fff',
    left: 0,
    top: 0,
    paddingTop: { base: 4, md: 6 },
    paddingX: { base: 4, md: 8 },
    zIndex: EZIndexLayer.CONTENT_VIEW
  })
})

export const ListingPageWrapper = chakra(CkFlex, {
  baseStyle: () => ({
    paddingBottom: 0,
    position: 'relative'
  })
})

export const LoadMoreButton = chakra(CkButton, {
  baseStyle: () => ({
    border: 'solid 1px',
    borderColor: 'background.grey.200',
    background: 'transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '2rem',
    height: { base: '48px', lg: '40px' }
  })
})

export const Text = chakra(CkText, {
  baseStyle: () => ({
    fontSize: 'lg',
    fontWeight: 600,
    color: 'text.grey.700',
    marginRight: '12px'
  })
})

export const ListingRoomWrapper = chakraShouldForwardProp(CkFlex, (props: BaseStyle) => ({
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'flex-start',
  width: { base: 'full', lg: props?.isEmpty ? 'full' : 'stretch' },
  marginBottom: { base: 10, lg: 20 }
}))

export const MapBoxWrapper = chakraShouldForwardProp(CkBox, (props: BaseStyle) => ({
  display:
    props?.size > 0
      ? {
          base: !props?.showMap ? 'none' : 'block',
          lg: 'block'
        }
      : 'none',
  position: { base: 'fixed', lg: 'sticky' },
  width: { base: 'full', lg: 'stretch' },
  height: 'stretch',
  top: { base: '64px', lg: '136px' }, // *INFO: 136px is the height filter bar (Every screen size are similar)
  left: 0,
  zIndex: { base: EZIndexLayer.MODAL, lg: EZIndexLayer.CONTENT_VIEW },
  '& > div': {
    width: { base: 'stretch', lg: 'stretch' }
  }
}))

export const RoomButtonShowMap = chakra(CkButton, {
  baseStyle: () => ({
    position: 'fixed',
    background: 'background.button',
    display: { base: 'flex', lg: 'none' },
    boxShadow: 'base',
    borderRadius: '20px',
    bottom: '22px',
    right: '20px',
    _hover: {
      background: 'background.buttonHover'
    }
  })
})

export const RoomButtonShowMapText = chakra(CkText, {
  baseStyle: () => ({
    fontWeight: 600,
    fontSize: 'lg',
    color: '#fff',
    marginLeft: '10px'
  })
})
