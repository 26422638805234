import dayjs from 'dayjs'
import isUndefined from 'lodash/isUndefined'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { ICoordinate } from 'types'
import { IProperty } from 'interfaces/property'
import { checkValidCoordinates } from 'utils/common'
import { IFilterForm } from './components/RoomFilter'
import { IListingQueryParams } from './constants'

export function getCoordinatesFromHouses(houses: IProperty[]): ICoordinate[] {
  const coordinates: ICoordinate[] = houses
    .filter((house) => checkValidCoordinates(house?.fakeLat ?? house?.lat ?? 0, house?.fakeLng ?? house?.lng ?? 0))
    .map((house: IProperty) => [house?.fakeLat ?? house?.lat ?? 0, house?.fakeLng ?? house?.lng ?? 0]) || [0, 0]
  return coordinates
}

export function getValidValue(newValue: string | undefined, currentValue: string | undefined): string | undefined {
  if (newValue === undefined || newValue === 'undefined') {
    return ''
  }
  const isDifferent: boolean = String(newValue) !== String(currentValue)
  const validOldValue: string = currentValue === undefined || currentValue === 'undefined' ? '' : currentValue
  const result: string = isDifferent ? newValue : validOldValue

  return result
}

export function getQueryParams(data: IFilterForm, query: NextParsedUrlQuery): IListingQueryParams {
  const {
    price: currentPrice,
    bathRoomType: currentBathRoomType,
    moveDate: currentMoveDate,
    metro: currentMetro,
    placeType: currentPlaceType
    //* INFO: Temporarily comment LeaseLengths to reuse later
    // leaseLength: currentLeaseLength
  } = query
  const { moveDate, metro, price, bathRoomType, placeType } = data
  const queryParams: IListingQueryParams = {
    moveDate: !isUndefined(moveDate)
      ? dayjs(moveDate).format('MM-DD-YYYY') !== String(currentMoveDate)
        ? dayjs(moveDate).format('MM-DD-YYYY')
        : currentMoveDate
      : undefined,
    metro: getValidValue(String(metro), String(currentMetro)),
    price: getValidValue(String(price?.value), String(currentPrice)),
    bathRoomType: getValidValue(String(bathRoomType?.value), String(currentBathRoomType)),
    placeType: getValidValue(String(placeType?.value), String(currentPlaceType))
    //* INFO: Temporarily comment LeaseLengths to reuse later
    // leaseLength: getValidValue(String(leaseLength?.value), String(currentLeaseLength))
  }

  return queryParams
}
