import { Image, Text, HStack, VStack } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { useMediaQuery } from 'react-responsive'
import { maxTabletWidth } from 'theme/globalStyles'
import { savedMoneyBlockText, savedMoneyBlockQuestion } from './constant'
import { SavedMoneyBlockContainer } from './savedMoneyBlock.style'
const SavedMoneyBlock = () => {
  const { firstLine, secondLine, thirdLine, boldText } = savedMoneyBlockText
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const isTablet: boolean = useBreakPoint(EBreakPoint.MD)
  const isSmallDesktopMin: boolean = useMediaQuery({ minWidth: maxTabletWidth })
  const isSmallDesktopMax: boolean = useMediaQuery({ maxWidth: 1400 })

  return (
    <SavedMoneyBlockContainer>
      {isTablet && (
        <VStack width={64} marginY={{ md: '69px', lg: 'auto' }} marginX={{ md: 10, lg: 8 }} spacing={6}>
          <HStack marginBottom={isSmallDesktopMin && isSmallDesktopMax ? 6 : 0} spacing={4} justifyContent="center">
            <Image width="112px" height="122px" src="/assets/images/saved_money_block.png" alt="savedMoneyBlock" />
            <Text width="128px" height="122px" fontSize="lg" fontWeight="500" color="teal.700" lineHeight={7}>
              {firstLine}
              {secondLine}
              {thirdLine}
              <b>{boldText}</b>
            </Text>
          </HStack>
          <VStack>
            <Text fontSize="lg" fontWeight="500" color="gray.700" lineHeight={7}>
              {savedMoneyBlockQuestion}
            </Text>
          </VStack>
        </VStack>
      )}
      {isMobile && (
        <VStack height="fit-content" margin={6}>
          <HStack height="max" spacing={4}>
            <Image width="72px" height="72px" src="/assets/images/saved_money_block.png" alt="savedMoneyBlock" />\
            <VStack spacing={4}>
              <Text fontSize="md" fontWeight="500" color="teal.700" lineHeight={6}>
                {firstLine}
                {secondLine}
                {thirdLine}
                <b>{boldText}</b>
              </Text>
              <Text fontSize="md" fontWeight="500" color="gray.700" lineHeight={6}>
                {savedMoneyBlockQuestion}
              </Text>
            </VStack>
          </HStack>
        </VStack>
      )}
    </SavedMoneyBlockContainer>
  )
}

export default SavedMoneyBlock
