import React, { useEffect, RefObject, useState } from 'react'
import {
  Button,
  chakra,
  Drawer,
  DrawerContent,
  DrawerOverlay,
  Flex,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader
} from '@chakra-ui/react'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { EBreakPoint, EZIndexLayer } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { useStores } from 'hooks/useStores'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { IRoomFilterItem } from 'interfaces/listing'
import { IMetro } from 'interfaces/metro'
import { getValidArray } from 'utils/common'
import RoomFilterForm from './components/RoomFilterForm'
import { bathroomType, roomFilterPrice, placeType } from './constants'
import {
  RoomFilterContainer,
  RoomFilterWrapper,
  Text,
  ButtonApplyFilter,
  ButtonModal,
  Container
} from './roomFilter.styles'

dayjs.extend(customParseFormat)

interface IRoomFilterProps {
  openModalFilter: boolean
  buttonRef?: RefObject<HTMLButtonElement>
  setQueryParams: (data: IFilterForm) => void
  setOpenModalFilter: (status: boolean) => void
  metroList: IMetro[]
}

export interface IFilterForm {
  limit?: number
  moveDate?: Date
  metro?: string
  price?: { label: string; value: string }
  leaseLength?: { label: string; value: string }
  bathRoomType?: { label: string; value: string }
  placeType?: { label: string; value: string }
}

const defaultValues: IFilterForm = {
  placeType: {
    label: placeType[0].title,
    value: String(placeType[0].value)
  }
}

const RoomFilter = (props: IRoomFilterProps) => {
  const { openModalFilter, setOpenModalFilter, setQueryParams, buttonRef, metroList } = props
  const methods: UseFormReturn = useForm<IFilterForm>({
    defaultValues: defaultValues
  })

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty }
  } = methods
  const { spinnerStore } = useStores()
  const { isLoading } = spinnerStore
  const isDesktop: boolean = useBreakPoint(EBreakPoint.LG)
  const router = useRouter()
  const { query } = router
  const [isLoadingReset, setIsLoadingReset] = useState<boolean>(false)

  function onSubmit(data: IFilterForm): void {
    setOpenModalFilter(false)
    setQueryParams(data)
  }

  function resetForm(): void {
    setIsLoadingReset(true)
    reset(defaultValues)
    setQueryParams(defaultValues)
  }

  function closeModal(): void {
    setOpenModalFilter(false)
  }

  useEffect(() => {
    const currentPriceFilter: IRoomFilterItem | undefined = getValidArray(roomFilterPrice).find(
      (roomFilter) => roomFilter?.value === query?.price
    )
    const currentBathRoomTypeFilter: IRoomFilterItem | undefined = getValidArray(bathroomType).find(
      (roomFilter) => roomFilter?.value === query?.bathRoomType
    )
    //* INFO: Temporarily comment LeaseLengths to reuse later
    // const currentLeaseLengthsFilter: IRoomFilterItem | undefined = getValidArray(leaseLengths).find(
    //   (roomFilter) => roomFilter?.value?.toString() === query?.leaseLength
    // )
    const currentMoveDateFilter: Date | undefined = query?.moveDate
      ? dayjs(`${query?.moveDate}`, 'MM/DD/YYYY').toDate()
      : undefined

    const currentPlaceTypeFilter: IRoomFilterItem =
      getValidArray(placeType).find((roomFilter) => roomFilter?.value === query?.placeType) || placeType[0]

    reset({
      metro: query?.metro,
      price: currentPriceFilter,
      moveDate: currentMoveDateFilter,
      bathRoomType: currentBathRoomTypeFilter,
      placeType: currentPlaceTypeFilter
      //* INFO: Temporarily comment LeaseLengths to reuse later
      // leaseLength: currentLeaseLengthsFilter,
    })

    if (isLoadingReset) {
      setIsLoadingReset(false)
    }
  }, [query])

  return (
    <Container>
      <FormProvider {...methods}>
        <chakra.form onSubmit={handleSubmit(onSubmit)} id="filter-form">
          {isDesktop ? (
            <RoomFilterWrapper>
              <RoomFilterContainer>
                <RoomFilterForm metroList={metroList} />
                <ButtonApplyFilter
                  colorScheme="teal"
                  type="submit"
                  form="filter-form"
                  isLoading={isSubmitting || isLoading}
                >
                  Apply filter
                </ButtonApplyFilter>
                <Button
                  variant="outline"
                  type="reset"
                  isLoading={isSubmitting || isLoadingReset}
                  onClick={resetForm}
                  hidden={!isDirty && Object.keys(query)?.length === 0}
                >
                  Reset
                </Button>
              </RoomFilterContainer>
            </RoomFilterWrapper>
          ) : (
            <Drawer
              isOpen={openModalFilter && !isDesktop}
              size="sm"
              placement="right"
              onClose={closeModal}
              finalFocusRef={buttonRef}
            >
              <DrawerOverlay />
              <DrawerContent zIndex={EZIndexLayer.MODAL}>
                <Flex flexDirection="column" height="stretch">
                  <ModalHeader
                    boxShadow="base"
                    zIndex={EZIndexLayer.NAV}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    height="60px"
                  >
                    <Text>Filter</Text>
                    <ModalCloseButton color="#000" onClick={closeModal} zIndex={EZIndexLayer.NAV} position="static" />
                  </ModalHeader>
                  <ModalBody>
                    <RoomFilterContainer>
                      <RoomFilterForm metroList={metroList} />
                    </RoomFilterContainer>
                  </ModalBody>
                  <ModalFooter placeItems="flex-end" marginBottom={4}>
                    <ButtonModal
                      hidden={!isDirty && Object.keys(query)?.length === 0}
                      resetButton
                      marginRight={4}
                      isLoading={isSubmitting || isLoadingReset}
                      onClick={resetForm}
                    >
                      Reset
                    </ButtonModal>
                    <ButtonModal
                      flex={1}
                      type="submit"
                      form="filter-form"
                      isLoading={isSubmitting || isLoading}
                      colorScheme="teal"
                    >
                      Apply filter
                    </ButtonModal>
                  </ModalFooter>
                </Flex>
              </DrawerContent>
            </Drawer>
          )}
        </chakra.form>
      </FormProvider>
    </Container>
  )
}

export default observer(RoomFilter)
