import { Box, Grid, HStack, Text, VStack } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import Icon from 'components/Icon'
import { trustCredibilityBlockText } from './constants'
import { CardText, Favicon, TrustCredibilityBlockContainer as Container } from './trustCredibilityBlock.style'

const TrustCredibilityBlock = () => {
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const isTablet: boolean = useBreakPoint(EBreakPoint.MD)

  return (
    <Container
      colSpan={{ base: 1, md: 2 }}
      paddingY={{ base: 6, md: 8 }}
      paddingLeft={{ base: 6, md: 8 }}
      paddingRight={{ base: 2, md: 8 }}
      height="fit-content"
    >
      <Favicon src="/assets/images/favicon_white.png" alt="favicon" isMobile={isMobile} />
      <VStack alignItems="flex-start">
        <Icon
          iconName="white_logo.svg"
          width={isMobile ? 162 : 242}
          height={isMobile ? 24 : 36}
          className="homeroom-logo"
        />
        <Text fontSize={{ base: 'md', md: 'lg' }} color="gray.50" fontWeight="medium" lineHeight={{ base: 6, md: 7 }}>
          Roommate-living you’ll actually love.
        </Text>
      </VStack>
      {isMobile && (
        <VStack alignItems="flex-start" marginTop={4} gap={3}>
          {trustCredibilityBlockText.map((line: string, index: number) => {
            return (
              <HStack key={`mobile-${index}`} gap={2} width="full">
                <Box flexShrink={0} display="flex" justifyContent="center" alignItems="center">
                  <Icon iconName="tick.svg" size={16} />
                </Box>
                <CardText fontSize="sm" lineHeight={5}>
                  {line}
                </CardText>
              </HStack>
            )
          })}
        </VStack>
      )}
      {isTablet && (
        <Grid marginTop={6} columnGap={6} rowGap={2} gridTemplateColumns="repeat(2, 1fr)">
          {trustCredibilityBlockText.map((line: string, index: number) => {
            return (
              <HStack key={index} gap={2} alignItems="flex-start">
                <Box marginTop={1} flexShrink={0} display="flex" justifyContent="center" alignItems="center">
                  <Icon iconName="tick.svg" size={16} />
                </Box>
                <CardText fontSize="md" lineHeight={6}>
                  {line}
                </CardText>
              </HStack>
            )
          })}
        </Grid>
      )}
    </Container>
  )
}

export default TrustCredibilityBlock
