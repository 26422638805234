import React, { ReactNode } from 'react'
import Carousel from 'nuka-carousel'
import ArrowControl from './components/ArrowControl'
import DotsControl from './components/DotsControl'
import styles from './multiCarousel.module.scss'

interface ICarouselProps {
  slidesToShow?: number
  slidesToScroll?: number
  cellSpacing?: number
  wrapAround?: boolean
  swiping?: boolean
  dragging?: boolean
  autoPlay?: boolean
  showArrows?: boolean
  showDots?: boolean
  speed?: number
  desktopDotMarginBottom?: string
  isDotSmall?: boolean
  className?: string
  setIsHovering?: (isHovering: boolean) => void
  children: ReactNode
  innerRef?: React.RefObject<HTMLInputElement>
}

const MultiCarousel = (props: ICarouselProps) => {
  const {
    slidesToShow,
    slidesToScroll,
    cellSpacing,
    wrapAround = true,
    swiping,
    dragging,
    showArrows = true,
    showDots = true,
    autoPlay = false,
    speed,
    desktopDotMarginBottom,
    isDotSmall = true,
    className,
    setIsHovering = () => {},
    children,
    innerRef
  } = props

  return (
    <Carousel
      innerRef={innerRef}
      className={className}
      slidesToShow={slidesToShow}
      slidesToScroll={slidesToScroll}
      cellSpacing={cellSpacing}
      swiping={swiping}
      wrapAround={wrapAround}
      dragging={dragging}
      autoplay={autoPlay}
      speed={speed}
      pauseOnHover
      dragThreshold={0.3}
      renderCenterLeftControls={(controlProps) => {
        if (!showArrows) {
          return <></>
        }
        return <ArrowControl setIsHovering={setIsHovering} type="prev" {...controlProps} />
      }}
      renderCenterRightControls={(controlProps) => {
        if (!showArrows) {
          return <></>
        }
        return <ArrowControl setIsHovering={setIsHovering} type="next" {...controlProps} />
      }}
      renderBottomCenterControls={(controlProps) => (
        <DotsControl
          {...controlProps}
          desktopDotMarginBottom={desktopDotMarginBottom}
          isDotSmall={isDotSmall}
          hidden={!showDots}
        />
      )}
      defaultControlsConfig={{
        containerClassName: styles.container
      }}
      style={{ overflowY: 'unset' }}
    >
      {children}
    </Carousel>
  )
}

export default MultiCarousel
