import { chakra, Flex as CkFlex, Button as CkButton, Text as CkText } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { EZIndexLayer } from 'enums/theme'

export const ResultWrapper = chakra(CkFlex, {
  baseStyle: () => ({
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingX: { base: 4, md: 8 },
    paddingTop: { base: 3, md: 4, lg: 6 },
    paddingBottom: { base: 3, md: 4, lg: 0 },
    marginBottom: '1px',
    background: 'white',
    position: { base: 'sticky', lg: 'unset' },
    top: { base: '64px', lg: 'unset' },
    zIndex: { base: EZIndexLayer.FILTER_BAR, lg: 'unset' },
    boxShadow: { base: 'base', lg: 'none' },
    '&:hover': {
      boxShadow: { base: 'md', lg: 'none' }
    }
  })
})

export const TextFilterResult = styled(
  chakra(CkText, {
    baseStyle: () => ({
      fontSize: 'md',
      fontWeight: 600,
      color: 'gray.500'
    })
  })
)`
  b {
    color: var(--chakra-colors-gray-700);
  }
`

export const FilterButtonModal = chakra(CkButton, {
  baseStyle: () => ({
    display: { lg: 'none' },
    border: 'solid 1px',
    backgroundColor: 'transparent',
    borderRadius: '6px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center'
  })
})
