import React, { ForwardedRef } from 'react'
import { Box } from '@chakra-ui/react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)
import Icon from 'components/Icon'
import { DatePickerText, DatePickerWrapper } from './roomFilterDatePicker.styles'

interface IRoomFilterDatePickerProps {
  value: Date | string | number
  onClick: () => void
}

export const RoomFilterDatePicker = (props: IRoomFilterDatePickerProps, ref: ForwardedRef<HTMLInputElement>) => {
  const { value, onClick } = props
  return (
    <DatePickerWrapper onClick={onClick} ref={ref}>
      <DatePickerText>
        {value && dayjs(value).isValid() ? dayjs(value).format('ddd, MMM Do') : 'Move-in date'}
      </DatePickerText>
      <Box width={5} height={5} alignSelf="center">
        <Icon iconName="date.svg" width={20} height={20} onClick={onClick} />
      </Box>
    </DatePickerWrapper>
  )
}
export default RoomFilterDatePicker
