import React from 'react'
import { Box } from '@chakra-ui/react'
import { CarouselSlideRenderControlProps } from 'nuka-carousel'
import DotIndicator from '../DotIndicator'

interface IDotsControlProps extends CarouselSlideRenderControlProps {
  desktopDotMarginBottom?: string
  isDotSmall?: boolean
  hidden?: boolean
}

const DotsControl = (props: IDotsControlProps) => {
  const { currentSlide, slideCount, desktopDotMarginBottom, isDotSmall, hidden, goToSlide } = props

  return (
    <Box hidden={slideCount <= 1 || hidden}>
      {Array.from(Array(slideCount).keys()).map((key) => {
        return (
          <DotIndicator
            key={key}
            active={currentSlide === key}
            isDotSmall={isDotSmall}
            desktopDotMarginBottom={desktopDotMarginBottom}
            onClick={() => {
              goToSlide(key)
            }}
          />
        )
      })}
    </Box>
  )
}

export default DotsControl
