import { chakra, Flex as CkFlex, Text as CkText } from '@chakra-ui/react'

export const DatePickerWrapper = chakra(CkFlex, {
  baseStyle: () => ({
    border: 'solid 1px',
    borderColor: 'background.grey.200',
    whiteSpace: 'nowrap',
    paddingLeft: { base: 4, lg: 4 },
    paddingRight: { base: 3, lg: 3 },
    paddingY: '7px',
    borderRadius: '6px',
    justifyContent: 'space-between',
    width: { base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' },
    height: { base: '48px', lg: '40px' },
    alignItems: 'center'
  })
})

export const DatePickerText = chakra(CkText, {
  baseStyle: () => ({
    color: 'text.grey.800',
    fontSize: { base: 'lg', lg: 'md' }
  })
})
