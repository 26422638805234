import React, { Fragment, useEffect, useState } from 'react'
import { DESKTOP_HOUSE_DEFAULT_IMAGE, MOBILE_HOUSE_DEFAULT_IMAGE } from 'enums/constants/images'
import { EPlaceTypeEnum } from 'enums/roomDefaultValue'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import { useStores } from 'hooks/useStores'
import { Map } from 'leaflet'
import identity from 'lodash/identity'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import dynamic from 'next/dynamic'
import { NextRouter, useRouter } from 'next/router'
import { BiChevronDown } from 'react-icons/bi'
import { ICoordinate } from 'types'
import { handleError } from 'API/error'
import Icon from 'components/Icon'
import { IHouseDefaultValue } from 'interfaces/houseDefaultValue'
import { ICarouseItem } from 'interfaces/listing'
import { IMetro } from 'interfaces/metro'
import { IProperty } from 'interfaces/property'
import { checkValidCoordinates, getCenterOfCoordinates, getValidArray } from 'utils/common'
import { getListingMedia } from '../DetailPage/utils'
import FilterResultBar from './components/FilterResultBar'
import SavedMoneyBlock from './components/LowerMidScroll/SavedMoneyBlock'
import TrustCredibilityBlock from './components/LowerMidScroll/TrustCredibilityBlock'
import RoomCard from './components/RoomCard'
import RoomCardSkeleton from './components/RoomCardSkeleton'
import RoomFilter, { IFilterForm } from './components/RoomFilter'
import { IMetroOption } from './components/RoomFilter/constants'
import WelcomeBar from './components/WelcomeBar'
import { IListingQueryParams } from './constants'
import {
  ListingPageContainer,
  ListingPageWrapper,
  ListingRoomWrapper,
  RoomButtonShowMap,
  RoomButtonShowMapText
} from './listingPage.styles'
import { Text, Container, LoadMoreButton, MapBoxWrapper } from './listingPage.styles'
import { getCoordinatesFromHouses, getQueryParams } from './utils'
const Mapbox = dynamic(() => import('./components/Mapbox'), { ssr: false })
const MapContainer = dynamic(
  async () => {
    const response = await import('node_modules/react-leaflet')
    return response.MapContainer
  },
  { ssr: false }
)
interface IListingPageProps {
  houseList: IProperty[]
  countHouseList: number
  defaultHouse: IHouseDefaultValue
  metroList: IMetro[]
}
const ListingPage = (props: IListingPageProps) => {
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const isDesktop: boolean = useBreakPoint(EBreakPoint.LG)
  const { houseList, countHouseList = 0, defaultHouse, metroList } = props
  const [mapModule, setMapModule] = useState<Map>()
  const [showMap, setShowMap] = useState<boolean>(false)
  const [center, setCenter] = useState<[number, number]>([0, 0])
  const [openModalFilter, setOpenModalFilter] = useState<boolean>(false)
  const router: NextRouter = useRouter()
  const { spinnerStore } = useStores()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { query, pathname } = router
  const { metro: currentMetro, placeType } = query
  const isEntireHouse: boolean = placeType === EPlaceTypeEnum.ENTIRE_HOUSE
  const [limit, setLimit] = useState<number>(isDesktop ? 9 : isMobile ? 7 : 11)
  const houseListWithPaging: IProperty[] = houseList.slice(0, limit)
  const validHouseListWithPaging: IProperty[] = getValidArray(houseListWithPaging)
  const desktopImage: string = defaultHouse?.desktopHouseDefaultImage ?? DESKTOP_HOUSE_DEFAULT_IMAGE
  const mobileImage: string = defaultHouse?.mobileHouseDefaultImage ?? MOBILE_HOUSE_DEFAULT_IMAGE
  function changePosition(lat: number, lng: number, zoomLevel?: number): void {
    try {
      if (checkValidCoordinates(lat, lng)) {
        setCenter([lat, lng])
        if (zoomLevel) {
          mapModule?.setView([lat, lng], zoomLevel)
        } else {
          mapModule?.setView([lat, lng])
        }
        mapModule?.invalidateSize()
      }
    } catch (error) {
      handleError(error as Error, 'components/pages/ListingPage/index.tsx', 'changePosition')
    }
  }
  function openMap(): void {
    setCenter(center)
    setShowMap(true)
    window && window.scrollTo(0, 0)
  }
  function loadMore(): void {
    const newLimit: number = countHouseList > limit + 10 ? limit + 10 : countHouseList
    setLimit(newLimit)
  }
  function setQueryParams(data: IFilterForm): void {
    spinnerStore.showLoading()
    setIsLoading(true)
    const queryParams: IListingQueryParams = getQueryParams(data, query)

    router.push({
      pathname,
      query: pickBy(queryParams, identity)
    })
  }
  function openFilterModal(): void {
    setOpenModalFilter(true)
  }

  useEffect(() => {
    setLimit(isDesktop ? 9 : isMobile ? 7 : 11)
  }, [isDesktop, isMobile])

  useEffect(() => {
    //INFO: disable loading state after push new route
    spinnerStore.hideLoading()
    setIsLoading(false)
  }, [query])

  useEffect(() => {
    if (Array.isArray(houseList) && mapModule) {
      const coordinates: ICoordinate[] = getCoordinatesFromHouses(houseList)
      const [centerLat, centerLng] = currentMetro ? getCenterOfCoordinates(coordinates) : coordinates[0] || [0, 0]
      const metroOption: IMetroOption = metroList.find((metro: IMetro) => metro.name === currentMetro) || {}
      const defaultZoom: number = currentMetro ? metroOption?.zoomLevel ?? 10 : 10
      changePosition(centerLat, centerLng, defaultZoom)
    }
  }, [houseList, mapModule])

  return (
    <Container>
      <RoomFilter
        openModalFilter={openModalFilter}
        setOpenModalFilter={setOpenModalFilter}
        setQueryParams={setQueryParams}
        metroList={metroList}
      />
      <ListingPageWrapper>
        <ListingRoomWrapper isEmpty={countHouseList <= 0}>
          {!isEntireHouse && <WelcomeBar />}
          <FilterResultBar countHouseList={countHouseList} onClick={openFilterModal} />
          <ListingPageContainer>
            {isLoading
              ? Array.from(Array(4)).map((card, index) => <RoomCardSkeleton key={index} />)
              : validHouseListWithPaging.map((house: IProperty, index: number) => {
                  const cardImages: ICarouseItem[] = getListingMedia(house?.media ?? [])
                  return (
                    <Fragment key={`card-${house.id}`}>
                      <RoomCard
                        changePosition={changePosition}
                        images={cardImages}
                        availableDate={house?.availableDate ?? new Date()}
                        {...omit(house, 'images')}
                        defaultImageUrl={isMobile ? mobileImage : desktopImage}
                        tags={getValidArray(house?.tags)}
                      />
                      {validHouseListWithPaging.length >= 4
                        ? index === 3 && !isEntireHouse && <TrustCredibilityBlock />
                        : index === 2 && !isEntireHouse && <TrustCredibilityBlock />}
                      {validHouseListWithPaging.length >= 7
                        ? index === 6 && !isEntireHouse && <SavedMoneyBlock />
                        : index ===
                            Math.min(
                              6,
                              isMobile && validHouseListWithPaging.length >= 6
                                ? 6
                                : Math.floor(validHouseListWithPaging.length / 2)
                            ) &&
                          !isEntireHouse && <SavedMoneyBlock />}
                    </Fragment>
                  )
                })}
            <RoomButtonShowMap onClick={openMap}>
              <Icon iconName="map_point.svg" size={16} />
              <RoomButtonShowMapText>Map</RoomButtonShowMapText>
            </RoomButtonShowMap>
          </ListingPageContainer>
          {limit < countHouseList && countHouseList > validHouseListWithPaging.length && (
            <LoadMoreButton onClick={loadMore}>
              <Text marginRight={2} lineHeight={7}>
                More Houses
              </Text>
              <BiChevronDown color="#000" size={18} />
            </LoadMoreButton>
          )}
        </ListingRoomWrapper>
        <MapBoxWrapper size={validHouseListWithPaging.length} showMap={showMap}>
          {validHouseListWithPaging.length > 0 && (showMap || isDesktop) && (
            <MapContainer
              center={center}
              zoom={10}
              minZoom={3}
              scrollWheelZoom={true}
              tap={false}
              doubleClickZoom
              zoomAnimation
              markerZoomAnimation
              fadeAnimation
              bounceAtZoomLimits
            >
              <Mapbox
                houseList={houseList}
                setMapModule={setMapModule}
                changePosition={changePosition}
                setShowMap={setShowMap}
                showMap={showMap}
              />
            </MapContainer>
          )}
        </MapBoxWrapper>
      </ListingPageWrapper>
    </Container>
  )
}
export default ListingPage
