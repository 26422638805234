import React, { createElement, forwardRef, Fragment, useMemo } from 'react'
import { Menu, MenuList } from '@chakra-ui/react'
import { EZIndexLayer } from 'enums/theme'
import DatePicker from 'react-datepicker'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import Dropdown from 'components/Dropdown'
import DropdownButton from 'components/Dropdown/DropdownButton'
import DropdownSelection from 'components/Dropdown/DropdownSelection'
import { IRoomFilterItem } from 'interfaces/listing'
import { IMetro } from 'interfaces/metro'
import { roomFilterPrice, bathroomType, placeType } from '../../constants'
import { SelectWrapper } from '../../roomFilter.styles'
import RoomFilterDatePicker from '../RoomFilterDatePicker'
import { sortMetroByOrder } from './utils'
interface IRoomFilterFormProps {
  metroList: IMetro[]
}

const RoomFilterForm = (props: IRoomFilterFormProps) => {
  const { metroList } = props
  const { setValue, control } = useFormContext()
  const startDate: Date = useWatch({ control, name: 'moveDate' })
  const metroValue: string = useWatch({ control, name: 'metro' })
  const price: IRoomFilterItem = useWatch({ control, name: 'price' })
  const bathroomTypeValue: IRoomFilterItem = useWatch({ control, name: 'bathRoomType' })
  const placeTypeValue: IRoomFilterItem = useWatch({ control, name: 'placeType' }) || placeType[0]
  //* INFO: Temporarily comment LeaseLengths to reuse later
  // const leaseLengthTime: IRoomFilterItem = useWatch({ control, name: 'leaseLength' })

  const DatePickerDropdown = () =>
    useMemo(
      () => (
        <SelectWrapper
          border={startDate ? '1px solid #319795' : 'none'}
          borderRadius={startDate ? '6px' : 'none'}
          className="date-wrapper"
          height={{ base: '48px', lg: '40px' }}
        >
          <Controller
            name="moveDate"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <DatePicker
                  selected={value}
                  onChange={(date) => onChange(date)}
                  customInput={createElement(forwardRef(RoomFilterDatePicker))}
                />
              )
            }}
          />
        </SelectWrapper>
      ),
      [startDate]
    )

  return (
    <Fragment>
      <Menu closeOnSelect={true} autoSelect={false} computePositionOnMount>
        {({ isOpen }) => (
          <Fragment>
            <DropdownButton
              item={{ title: metroValue, value: metroValue }}
              placeHolder="Select Metro"
              isOpen={isOpen}
            />
            <MenuList
              maxHeight="calc(100vh - 140px)"
              overflowY="auto"
              zIndex={EZIndexLayer.CONTENT_VIEW}
              minWidth="auto"
            >
              {sortMetroByOrder(metroList).map((metro: IMetro, index: number) => (
                <DropdownSelection
                  key={`metro-${index}`}
                  onClick={() => setValue('metro', metro.name, { shouldDirty: true })}
                  label={metro?.name ?? ''}
                  isSelected={metro.name === metroValue}
                  width={{ base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' }}
                />
              ))}
            </MenuList>
          </Fragment>
        )}
      </Menu>
      <Dropdown
        name="price"
        text="Price"
        item={price}
        options={roomFilterPrice}
        setValue={(name, value) => setValue(name, value, { shouldDirty: true })}
        width={{ base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' }}
      />
      <DatePickerDropdown />
      {/* INFO: Temporarily comment this LeaseLength feature to reuse later */}
      {/* <Dropdown
        name="leaseLength"
        text="Lease length"
        item={leaseLengthTime}
        options={leaseLengths}
        setValue={(name, value) => setValue(name, value, { shouldDirty: true })}
        width={{ base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' }}
      /> */}
      <Dropdown
        name="bathRoomType"
        text="Bathroom type"
        item={bathroomTypeValue}
        options={bathroomType}
        setValue={(name, value) => setValue(name, value, { shouldDirty: true })}
        width={{ base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' }}
      />
      <Dropdown
        name="placeType"
        text="Type of place"
        item={placeTypeValue}
        options={placeType}
        setValue={(name, value) => setValue(name, value, { shouldDirty: true })}
        width={{ base: 'min(calc(100vw - 48px), 400px)', md: '400px', lg: '178px' }}
      />
    </Fragment>
  )
}

export default RoomFilterForm
