import { chakra, Box as CkBox, Text as CkText, Flex as CkFlex, Button as CkButton } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { BaseStyle } from 'types'
import chakraShouldForwardProp from 'utils/chakraShouldForwardProp'

export const RoomCardWrapper = chakraShouldForwardProp(CkFlex, (props: BaseStyle) => ({
  position: 'relative',
  marginX: 'auto',
  width: { base: props?.isSingleRoom ? 'full' : 'calc(100vw - 100px)', md: 'full' },
  justifyContent: 'center',
  paddingX: { base: '2px', md: 0 }
}))

export const RoomCardContainer = chakra(CkBox, {
  baseStyle: () => ({
    width: '100%',
    height: '100%',
    marginX: 'auto',
    background: '#FFFFFF',
    boxShadow: { base: 'base', lg: 'unset' },
    border: { base: 'unset', lg: '1px solid #E2E8F0' },
    borderRadius: '8px',
    rounded: 'lg',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: 'md'
    },
    cursor: 'pointer'
  })
})

export const RoomCardBioContainer = chakra(CkBox, {
  baseStyle: () => ({
    paddingY: 4,
    paddingX: 6
  })
})

export const RoomCardBioTitle = chakraShouldForwardProp(CkText, (props: BaseStyle) => ({
  color: props?.blackColor ? 'text.primary' : 'text.secondary',
  fontWeight: 600,
  fontSize: '1rem',
  marginBottom: '8px !important',
  marginTop: '4px !important',
  transition: 'all 250ms',
  cursor: 'pointer',
  '&:hover': {
    transition: 'all 250ms',
    color: 'text.secondary'
  }
}))

export const RoomCardBioFooter = chakra(CkFlex, {
  baseStyle: () => ({
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer'
  })
})

export const RoomCardBioText = chakraShouldForwardProp(CkText, (props: BaseStyle) => ({
  color: props?.price ? 'text.secondary' : 'text.grey.600',
  fontWeight: props?.bold ? 600 : props?.normal === 'true' ? 'normal' : 500,
  fontSize: '14px',
  whiteSpace: 'wrap',
  marginLeft: props?.bold || props?.hasMarginLeft ? '6px !important' : 0,
  marginY: '0 !important'
}))

export const RoomButtonShowMap = chakra(CkButton, {
  baseStyle: () => ({
    position: 'absolute',
    background: 'background.button',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'base',
    top: { base: '65%', lg: '-30px' }
  })
})

export const RoomButtonShowMapText = chakra(CkText, {
  baseStyle: () => ({
    fontWeight: 600,
    fontSize: 'lg',
    color: '#fff',
    marginLeft: 2.5
  })
})

export const Flex = styled(
  chakraShouldForwardProp(CkFlex, (props: BaseStyle) => ({
    width: '100%',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: props?.price ? '8px' : 0
  }))
)`
  img {
    min-width: 16px !important;
  }
  > div:first-of-type > div:first-of-type {
    min-width: 16px !important;
    align-self: flex-start;
    padding-top: 8px;
  }
`

export const IconWrapper = chakra(CkFlex, {
  baseStyle: () => ({
    marginX: 0.5,
    justifyContent: 'center'
  })
})

export const RoomCardViewHouseButton = chakra(CkButton, {
  baseStyle: () => ({
    background: 'white',
    border: '1px solid #E2E8F0',
    borderRadius: '6px',
    display: 'flex',
    width: 'calc(100% - 48px)',
    justifyContent: 'center',
    alignItems: 'center',
    marginX: 6,
    marginBottom: 5,
    height: { base: '48px', lg: '40px' }
  })
})

export const RoomCardViewText = chakra(CkText, {
  baseStyle: () => ({
    fontSize: { base: 'lg', lg: 'md' },
    lineHeight: { base: 7, lg: 6 },
    color: '#2D3748',
    fontWeight: 600,
    marginRight: '8px !important'
  })
})

export const RoomCardButtonClosePopup = chakra(CkButton, {
  baseStyle: () => ({
    background: 'background.white.500',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    zIndex: 1000000,
    width: 10,
    height: 10,
    padding: 0
  })
})

export const RoomInfoRowMobile = styled(CkFlex)`
  span {
    width: 20px !important;
  }
`
