import React, { useState, useEffect } from 'react'
import NextImage from 'next/image'
import { placeholderImage } from './constants'
const defaultImage: string = '/assets/images/placeholder_image_desktop.jpg'

type ObjectFit = NonNullable<JSX.IntrinsicElements['img']['style']>['objectFit']
export interface INextImageProps {
  src: string
  alt?: string
  quality?: number
  objectFit?: ObjectFit
  defaultImageUrl?: string
  priority?: boolean
  style?: React.CSSProperties
}

export interface INaturalImageSize {
  naturalWidth: number
  naturalHeight: number
}

const Image = (props: INextImageProps) => {
  const { src, alt, quality = 100, defaultImageUrl, priority, style } = props
  const [imageSrc, setImageSrc] = useState<string | undefined>(src)
  const [currentQuality, setCurrentQuality] = useState<number>(quality)
  const [objectFit, setObjectFit] = useState<ObjectFit>(props?.objectFit ?? 'cover')
  function setDefaultImage(): void {
    setImageSrc(defaultImageUrl ?? defaultImage)
  }
  function changeObjectFit(naturalSize: INaturalImageSize): void {
    const { naturalWidth, naturalHeight } = naturalSize
    if (naturalHeight > naturalWidth) {
      setObjectFit('contain')
    }
  }

  useEffect(() => {
    if (imageSrc === defaultImage || imageSrc === defaultImageUrl) {
      setCurrentQuality(100)
    }
  }, [imageSrc])

  return (
    <NextImage
      alt={alt}
      placeholder="blur"
      sizes="100%"
      objectPosition="50% 50%"
      blurDataURL={placeholderImage}
      src={imageSrc ?? defaultImageUrl ?? defaultImage}
      onLoadingComplete={changeObjectFit}
      quality={currentQuality}
      layout="fill"
      objectFit={objectFit}
      onErrorCapture={setDefaultImage}
      onError={setDefaultImage}
      priority={priority}
      style={style}
    />
  )
}
export default Image
