import React, { useEffect, useState } from 'react'
import { Box, HStack } from '@chakra-ui/react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import Icon from 'components/Icon'
import { StaticBarWrapper, WelcomeMessage, CloseButton } from './welcomeBar.styles'

const WelcomeBar = () => {
  const [isWelcomeMessageOpen, setIsWelcomeMessageOpen] = useState<boolean>(true)
  const [closeButtonSize, setCloseButtonSize] = useState<'sm' | 'md'>('sm')
  const isDesktop: boolean = useBreakPoint(EBreakPoint.LG)
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)

  function turnOffWelcomeMessage(): void {
    setIsWelcomeMessageOpen(false)
  }

  useEffect(() => {
    setCloseButtonSize(isDesktop ? 'sm' : 'md')
  }, [isDesktop])

  return (
    <StaticBarWrapper hidden={!isWelcomeMessageOpen} order={{ base: 0, lg: -1 }}>
      <HStack spacing={0} textAlign="center">
        <WelcomeMessage>
          Welcome to HomeRoom! {isMobile ? <br /> : <></>} Roommate-living you’ll actually
        </WelcomeMessage>
        <Box alignSelf="flex-end" height={5}>
          <Icon iconName="heart_solid.svg" size={isMobile ? 16 : 20} />
        </Box>
      </HStack>
      <CloseButton size={closeButtonSize} onClick={turnOffWelcomeMessage} />
    </StaticBarWrapper>
  )
}

export default WelcomeBar
