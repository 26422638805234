import { chakra, Box as CkBox, Text as CkText, Flex as CkFlex, Button as CkButton } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { EZIndexLayer } from 'enums/theme'
import { BaseStyle } from 'types'
import chakraShouldForwardProp from 'utils/chakraShouldForwardProp'

export const RoomCardWrapper = chakra(CkFlex, {
  baseStyle: () => ({
    position: 'relative',
    marginX: 'auto',
    width: '100%',
    justifyContent: 'center'
  })
})

export const RoomCardContainer = chakraShouldForwardProp(CkBox, (props: BaseStyle) => ({
  width: '100%',
  height: '100%',
  marginX: 'auto',
  background: '#FFFFFF',
  boxShadow: 'base',
  '&:hover': {
    boxShadow: 'md'
  },
  rounded: 'lg',
  overflow: 'hidden',
  cursor: props?.popup ? 'unset' : 'pointer'
}))

export const RoomCardBioContainer = chakra(CkBox, {
  baseStyle: () => ({
    padding: '20px 24px'
  })
})

export const RoomCardBioTitle = chakraShouldForwardProp(CkText, (props: BaseStyle) => ({
  color: props?.blackColor ? 'text.primary' : 'text.secondary',
  fontWeight: 600,
  fontSize: '1rem',
  marginTop: '0 !important',
  marginBottom: '8px !important',
  transition: 'all 250ms',
  '&:hover': {
    transition: 'all 250ms',
    color: 'text.secondary'
  }
}))

export const RoomCardBioFooter = chakra(CkFlex, {
  baseStyle: () => ({
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'space-between',
    alignItems: 'center'
  })
})

export const RoomCardBioText = chakraShouldForwardProp(CkText, (props: BaseStyle) => ({
  color: props?.price ? 'text.secondary' : 'text.grey.600',
  fontWeight: props?.bold ? 600 : props?.price ? 700 : props?.normal === 'true' ? 'normal' : 400,
  fontSize: props?.price ? '1rem' : '14px',
  marginLeft: props?.bold ? '4px !important' : 0,
  lineHeight: props?.price ? '1.5rem' : '20px',
  marginY: '0 !important'
}))

export const Flex = styled(
  chakraShouldForwardProp(CkFlex, (props: BaseStyle) => ({
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: props?.price ? '8px' : 0
  }))
)`
  > div:first-of-type > div:first-of-type {
    min-width: 16px !important;
    align-self: flex-start;
    padding-top: 8px;
  }
`

export const IconWrapper = chakra(CkFlex, {
  baseStyle: () => ({
    marginX: '8px'
  })
})

export const RoomCardViewHouseButton = chakra(CkButton, {
  baseStyle: () => ({
    width: '100%',
    background: 'background.button',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: { base: 12, md: 10 },
    lineHeight: { base: 7, md: 6 },
    marginBottom: 5,
    _hover: {
      background: 'background.buttonHover'
    }
  })
})

export const RoomCardViewText = chakra(CkText, {
  baseStyle: () => ({
    fontSize: { base: 'lg', md: 'md' },
    color: 'white',
    fontWeight: 600,
    marginRight: '8px !important'
  })
})

export const RoomCardButtonClosePopup = chakra(CkButton, {
  baseStyle: () => ({
    background: 'background.white.500',
    borderRadius: '6px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    right: '1rem',
    top: '1rem',
    zIndex: EZIndexLayer.CONTENT_VIEW,
    width: '40px',
    height: '40px',
    padding: 0
  })
})
