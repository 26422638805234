export interface ISavedMoneyBlockText {
  firstLine: string
  secondLine: string
  thirdLine: string
  boldText: string
}

export const savedMoneyBlockText: ISavedMoneyBlockText = {
  firstLine: 'HomeRoom ',
  secondLine: 'members save ',
  thirdLine: 'an average of ',
  boldText: '50% on rent'
}

export const savedMoneyBlockQuestion: string = 'What will you do with all that spare cash?'
