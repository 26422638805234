import { Box as CkBox, chakra } from '@chakra-ui/react'

export const SavedMoneyBlockContainer = chakra(CkBox, {
  baseStyle: () => ({
    height: { base: 'fit-content', md: 'full' },
    minHeight: { base: 40, md: '330px', lg: 80 },
    width: '100%',
    marginX: 'auto',
    background: '#F3EADA',
    boxShadow: 'md',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      boxShadow: 'md'
    },
    rounded: 'lg',
    overflow: 'hidden'
  })
})
