import React, { SyntheticEvent } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { CategoryEnum, ActionEnum } from 'enums/analytics'
import { CarouselSlideRenderControlProps } from 'nuka-carousel'
import Icon from 'components/Icon'
import { logEvent } from 'utils/analytics'

interface IArrowProps extends CarouselSlideRenderControlProps {
  type: 'next' | 'prev'
  setIsHovering: (isHovering: boolean) => void
}

const Arrow = (props: IArrowProps) => {
  const { type, slideCount, previousSlide, nextSlide, setIsHovering } = props
  const isNextArrow: boolean = type === 'next'
  const iconName: string = isNextArrow ? 'circle_right_arrow.svg' : 'circle_left_arrow.svg'
  const arrowProps: BoxProps = isNextArrow ? { marginRight: '1.5rem' } : { marginLeft: '1.5rem' }

  function handleOnClick(event?: SyntheticEvent): void {
    event?.stopPropagation()
    isNextArrow ? nextSlide() : previousSlide()

    logEvent(
      CategoryEnum.USER,
      isNextArrow ? ActionEnum.CAROUSEL_NEXT_IMAGE_CLICKED : ActionEnum.CAROUSEL_PREVIOUS_IMAGE_CLICKED
    )
  }

  return (
    <Box onMouseEnter={() => setIsHovering(true)} hidden={slideCount <= 1} cursor="pointer" {...arrowProps}>
      <Icon iconName={iconName} onClick={handleOnClick} width={40} height={40} />
    </Box>
  )
}

export default Arrow
