import React from 'react'
import { EBreakPoint } from 'enums/theme'
import useBreakPoint from 'hooks/useBreakPoint'
import identity from 'lodash/identity'
import omit from 'lodash/omit'
import pickBy from 'lodash/pickBy'
import { NextRouter, useRouter } from 'next/router'
import { IoFilter } from 'react-icons/io5'
import { FilterButtonModal, ResultWrapper, TextFilterResult } from './filterResultBar.styles'

interface IFilterResultBarProps {
  countHouseList: number
  onClick: () => void
}

const FilterResultBar = (props: IFilterResultBarProps) => {
  const { countHouseList, onClick } = props
  const router: NextRouter = useRouter()
  const { query } = router
  const { metro: currentMetro } = query
  const isMobile: boolean = useBreakPoint(EBreakPoint.BASE, EBreakPoint.MD)
  const numberFiltered: number = Object.keys(pickBy(omit(query, 'limit'), identity)).length

  return (
    <ResultWrapper order={{ base: -1, lg: 0 }}>
      {countHouseList > 0 ? (
        <TextFilterResult>
          {isMobile ? '' : 'Browse'} <b>{countHouseList}</b> {isMobile ? '' : 'move-in ready'} home
          {countHouseList > 1 ? 's ' : ' '}
          {currentMetro ? 'in ' : ''}
          <b>{currentMetro ? currentMetro + (isMobile ? '' : '.') : ''}</b>
        </TextFilterResult>
      ) : (
        <TextFilterResult>No rooms available this time</TextFilterResult>
      )}
      <FilterButtonModal
        colorScheme="white"
        onClick={onClick}
        borderColor={numberFiltered > 0 ? 'teal.500' : 'gray.200'}
      >
        <IoFilter color={numberFiltered > 0 ? '#319795' : '#2D3748'} size={20} />
      </FilterButtonModal>
    </ResultWrapper>
  )
}

export default FilterResultBar
