import { chakra, Text as CkText, HStack as CkHStack, CloseButton as CkCloseButton } from '@chakra-ui/react'
import { EZIndexLayer } from 'enums/theme'

export const StaticBarWrapper = chakra(CkHStack, {
  baseStyle: () => ({
    width: { base: 'stretch', lg: '50vw' },
    justifyContent: 'center',
    backgroundColor: 'teal.100',
    padding: { base: '16px', md: '22px', lg: '18px' },
    position: 'sticky',
    transform: { base: 'translateY(-1px)', lg: 'unset' },
    zIndex: EZIndexLayer.WELCOME_BAR,
    top: { base: '129px', md: '137px', lg: '136px' },
    boxShadow: 'base',
    alignSelf: 'flex-start'
  })
})

export const CloseButton = chakra(CkCloseButton, {
  baseStyle: () => ({
    alignSelf: 'flex-end',
    backgroundColor: 'blackAlpha.100',
    _hover: {
      backgroundColor: 'blackAlpha.200'
    },
    _active: {
      backgroundColor: 'blackAlpha.300'
    },
    _focus: {
      backgroundColor: 'blackAlpha.300',
      boxShadow: 'none'
    },
    position: 'absolute',
    right: { base: 4, md: 8, lg: 4 },
    top: 4
  })
})

export const WelcomeMessage = chakra(CkText, {
  baseStyle: () => ({
    fontSize: { base: 'xs', md: 'sm' },
    fontWeight: { base: 500, lg: 600 },
    lineHeight: { base: 4, md: 5 },
    color: '#242E2E',
    paddingRight: 1
  })
})
