import React, { SyntheticEvent } from 'react'
import { Box } from '@chakra-ui/react'

interface IDotIndicatorProps {
  isDotSmall?: boolean
  desktopDotMarginBottom?: string
  active?: boolean
  onClick: () => void
}

const DotIndicator = (props: IDotIndicatorProps) => {
  const { isDotSmall, desktopDotMarginBottom, active, onClick } = props

  function handleOnClick(event?: SyntheticEvent): void {
    event?.stopPropagation()
    onClick()
  }

  return (
    <Box
      data-testid="dot-indicator"
      width={isDotSmall ? '8px' : { base: '8px', md: '16px' }}
      height={isDotSmall ? '8px' : { base: '8px', md: '16px' }}
      marginLeft={isDotSmall ? '3px' : { base: '3px', md: '8px' }}
      marginRight={isDotSmall ? '3px' : { base: '3px', md: '8px' }}
      marginBottom={desktopDotMarginBottom ? { base: '14px', lg: props.desktopDotMarginBottom } : '16px'}
      backgroundColor={active ? '#fff' : 'background.grey.500'}
      rounded="50%"
      display="inline-block"
      transition="background-color 0.6s ease"
      cursor="pointer"
      _hover={{ backgroundColor: '#fff' }}
      _focus={{ backgroundColor: '#fff' }}
      _active={{ backgroundColor: '#fff' }}
      onClick={handleOnClick}
    />
  )
}

export default DotIndicator
