import { chakra, Image, Text, GridItem as CkGridItem } from '@chakra-ui/react'
import { BaseStyle } from 'types'
import chakraShouldForwardProp from 'utils/chakraShouldForwardProp'

export const TrustCredibilityBlockContainer = chakra(CkGridItem, {
  baseStyle: () => ({
    width: '100%',
    height: '100%',
    marginX: 'auto',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: 'linear-gradient(281.35deg, #319795 0.99%, #38736B 94.71%)',
    boxShadow: 'base',
    rounded: 'lg',
    overflow: 'hidden',
    '&:hover': {
      boxShadow: 'md'
    }
  })
})

export const Favicon = chakraShouldForwardProp(Image, (props: BaseStyle) => ({
  position: 'absolute',
  right: props?.isMobile ? '-32px' : '0px',
  top: props?.isMobile ? '68px' : '-36px',
  opacity: 0.05
}))

export const CardText = chakra(Text, {
  baseStyle: () => ({
    color: 'white',
    fontWeight: 'normal',
    margin: '0px !important'
  })
})
