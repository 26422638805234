import { EBathRoomTypeEnum, EPlaceTypeEnum } from 'enums/roomDefaultValue'
import { IRoomFilterItem } from 'interfaces/listing'
import routes from 'routes'

export const roomFilterPrice: IRoomFilterItem[] = [
  {
    title: 'Less than $400',
    value: '0-400'
  },
  {
    title: '$400 - $500',
    value: '400-500'
  },
  {
    title: '$500 - $600',
    value: '500-600'
  },
  {
    title: '$600 - $700',
    value: '600-700'
  },
  {
    title: 'More than $700',
    value: '700-'
  }
]

export const leaseLengths: IRoomFilterItem[] = [
  {
    title: '3 months',
    value: 3
  },
  {
    title: '6 months',
    value: 6
  },
  {
    title: '12 months',
    value: 12
  },
  {
    title: '18 months',
    value: 18
  }
]

export const bathroomType: IRoomFilterItem[] = [
  {
    title: 'Private bathroom',
    value: EBathRoomTypeEnum.PRIVATE_BATHROOM
  },
  {
    title: 'Shared bathroom',
    value: EBathRoomTypeEnum.SHARED_BATHROOM
  }
]

export const placeType: IRoomFilterItem[] = [
  {
    title: 'Private room',
    value: EPlaceTypeEnum.PRIVATE_ROOM
  },
  {
    title: 'Entire house',
    value: EPlaceTypeEnum.ENTIRE_HOUSE
  }
]

export interface IMetroOption {
  label?: string
  value?: string
  zoomLevel?: number
  imageUrl?: string
}

export const metroList: IMetroOption[] = [
  {
    label: 'Kansas City',
    value: `${routes.home.value}?metro=Kansas+City`,
    zoomLevel: 10,
    imageUrl: '/assets/images/metro/kansas_city.png'
  },
  {
    label: 'Dallas-Fort Worth',
    value: `${routes.home.value}?metro=Dallas-Fort+Worth`,
    zoomLevel: 9,
    imageUrl: '/assets/images/metro/dallas_fort_worth.png'
  },
  {
    label: 'San Antonio',
    value: `${routes.home.value}?metro=San+Antonio`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/san_antonio.png'
  },
  {
    label: 'Austin',
    value: `${routes.home.value}?metro=Austin`,
    zoomLevel: 12,
    imageUrl: '/assets/images/metro/austin.png'
  },
  {
    label: 'Pittsburgh',
    value: `${routes.home.value}?metro=Pittsburgh`,
    zoomLevel: 12,
    imageUrl: '/assets/images/metro/pittsburgh.png'
  },
  {
    label: 'Indianapolis',
    value: `${routes.home.value}?metro=Indianapolis`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/indianapolis.png'
  },
  {
    label: 'Phoenix',
    value: `${routes.home.value}?metro=Phoenix`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/phoenix.png'
  },
  {
    label: 'Tampa',
    value: `${routes.home.value}?metro=Tampa`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/tampa.png'
  },
  {
    label: 'Houston',
    value: `${routes.home.value}?metro=Houston`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/houston.png'
  },
  {
    label: 'Atlanta',
    value: `${routes.home.value}?metro=Atlanta`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/atlanta.png'
  }
]

export const metroListWithAllCity: IMetroOption[] = [
  ...metroList,
  {
    label: 'All Cities',
    value: `${routes.home.value}`,
    zoomLevel: 11,
    imageUrl: '/assets/images/metro/all_city.png'
  }
]
