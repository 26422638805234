import { MediaType } from 'enums/media'
import get from 'lodash/get'
import { BaseStyle } from 'types'
import { IIconWithTextProps } from 'components/IconWithText'
import { IAmenitiesPackage } from 'interfaces/amenitiesPackage'
import { ICarouseItem } from 'interfaces/listing'
import { IMedia } from 'interfaces/media'
import { IProperty } from 'interfaces/property'
import { IUnitWithRelations } from 'interfaces/unit'
import { getValidArray } from 'utils/common'
import { AMENITIES_ICON_MAP, defaultAmenities } from './constants'

export function getButtonProps(isActive: boolean, isFullWidth?: boolean): BaseStyle {
  return isActive
    ? {
        colorScheme: 'teal',
        borderColor: 'teal',
        isFullWidth: isFullWidth ?? undefined
      }
    : { isFullWidth: isFullWidth ?? undefined }
}

export function getMedia(media: IMedia[], type: MediaType = MediaType.IMAGE): ICarouseItem[] {
  return getValidArray(media)
    ?.filter((mediaDetail: IMedia) => mediaDetail.type === type)
    ?.sort((a: IMedia, b: IMedia) => a.order - b.order)
    ?.map((mediaDetail: IMedia) => ({
      name: mediaDetail.name,
      url: mediaDetail.url ?? mediaDetail?.buildiumUrl
    }))
}

export function getListingMedia(media: IMedia[]): ICarouseItem[] {
  return getValidArray(media).map((mediaDetail: IMedia) => ({
    name: mediaDetail?.name,
    url: mediaDetail?.url
  }))
}

export function getAmenitiesDataWithIcon(amenities: IAmenitiesPackage, enabledKeys: string[]): IIconWithTextProps[] {
  let amenitiesData: IIconWithTextProps[] = []

  for (const key in amenities) {
    if (enabledKeys.includes(key)) {
      const defaultLabel: string = get(defaultAmenities, key)
      const label: string = get(amenities, key, defaultLabel)
      const iconName: string = AMENITIES_ICON_MAP[key]

      if (label) {
        amenitiesData.push({
          label,
          iconName
        })
      }
    }
  }

  return amenitiesData
}

export function getAllBuildiumUnitIds(houseDetail: IProperty): number[] {
  return getValidArray(houseDetail?.units).map((unit: IUnitWithRelations) => unit?.buildiumUnitId ?? 0)
}

export function getImageMediaUnit(media: IMedia[]): ICarouseItem[] {
  return getValidArray(media)
    ?.filter((mediaDetail: IMedia) => mediaDetail.type === MediaType.IMAGE)
    ?.sort((mediaItemA: IMedia, mediaItemB: IMedia) => (mediaItemA?.unitOrder ?? 0) - (mediaItemB?.unitOrder ?? 0))
    ?.map((mediaDetail: IMedia) => ({
      name: mediaDetail.name,
      url: mediaDetail.url ?? mediaDetail?.buildiumUrl
    }))
}

export const leadingNumberRegex: RegExp = /[0-9]+.\s+/
