import React from 'react'
import { AspectRatio, Skeleton, SkeletonText } from '@chakra-ui/react'
import { RoomCardBioContainer, RoomCardContainer, RoomCardWrapper } from '../RoomCard/roomCard.styles'

const RoomCardSkeleton = () => {
  return (
    <RoomCardWrapper>
      <RoomCardContainer>
        <AspectRatio ratio={16 / 10}>
          <Skeleton size="full" />
        </AspectRatio>
        <RoomCardBioContainer>
          <SkeletonText noOfLines={3} spacing="4" />
        </RoomCardBioContainer>
      </RoomCardContainer>
    </RoomCardWrapper>
  )
}

export default RoomCardSkeleton
